<template>
  <div>
    <!-- 调查居民 -->
    <div class="resident-btns">
      <div class="title">
        <span class="blue"></span>
        <p>调查居民信息</p>
      </div>
      <div class="btns" v-if="preTaskStatus != 2">
        <el-button class="btns-item" type="primary" @click="addResidentRow">新增人员</el-button>
        <el-upload class="btns-item upload-item" :action="upload" accept=".xls, .xlsx" :show-file-list="false"
          :headers="myHeaders" :on-success="handelSuccess">
          <el-button type="primary" style="width: 120px">导入</el-button>
        </el-upload>
        <el-button class="btns-item" type="primary" @click="setResidentInfo('1')">设置流调员</el-button>
        <el-button class="btns-item" type="primary" @click="setResidentInfo('2')">设置涉事场所</el-button>
        <el-button type="primary" style="width: 120px" @click="downloadTemplate()">下载导入模板</el-button>
      </div>
    </div>
    <el-form :model="userListForm" :rules="userListFormRules" ref="userListFormRef">
      <el-table :is-show-select="true" :data="userListForm.userList" table-size="medium" :border="true"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" :selectable="selectable"></el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button v-if="scope.row.surveyStatus == 0" type="text" size="small"
              @click="deleteResidentRow(scope.$index)">删除</el-button>
            <el-button v-else-if="scope.row.surveyStatus != 0" type="text" size="small"
              @click="residentDetail(scope.row)">详情</el-button>
          </template>
        </el-table-column>
        <el-table-column label="姓名" width="120">
          <template slot="header">
            <span style="color: #f56c6c">*</span>
            <span style="margin-left: 6px">姓名</span>
          </template>
          <template slot-scope="scope">
            <el-form-item :prop="`userList.${scope.$index}.name`" :rules="userListFormRules.name">
              <span v-if="scope.row.surveyStatus == 2">
                {{ scope.row.name }}
              </span>
              <el-input v-else v-model.trim="scope.row.name" placeholder="请输入姓名"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="cardNo" label="身份证号码" width="220">
          <template slot-scope="scope">
            <el-form-item :prop="`userList.${scope.$index}.cardNo`">
              <!-- :rules="userListFormRules.cardNo" -->

              <span v-if="scope.row.surveyStatus == 2">
                {{ scope.row.cardNo }}
              </span>
              <el-input v-else class="select-width" v-model.trim="scope.row.cardNo" placeholder="请输入证件号" />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="linkPhone" label="联系电话" width="160">
          <template slot-scope="scope">
            <el-form-item :prop="`userList.${scope.$index}.linkPhone`">
              <!-- :rules="userListFormRules.linkPhone" -->

              <span v-if="scope.row.surveyStatus == 2">
                {{ scope.row.linkPhone }}
              </span>
              <el-input v-else class="select-width" maxlength="11" v-model.trim.number="scope.row.linkPhone"
                placeholder="请输入联系电话" />
            </el-form-item>
          </template>
        </el-table-column>

        <!-- <el-table-column :rules="userListFormRules.surveyUserName" prop="surveyType" label="调查类型" width="180">
          <template slot="header">
            <span style="color: #f56c6c">*</span>
            <span style="margin-left: 6px">调查类型</span>
          </template>
          <template slot-scope="scope">
            <el-form-item :prop="`userList.${scope.$index}.surveyType`">
              <el-select filterable clearable class="select-width" v-model="scope.row.surveyType" placeholder="请选择调查类型">
                <el-option v-for="item in bingliList" :key="item.label" :label="item.label"
                  :value="item.value" @click.native="handleBinli(item, scope.row)"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column> -->

        <el-table-column prop="surveyUserName" label="调查类型" width="180">
          <template slot="header">
            <span style="color: #f56c6c">*</span>
            <span style="margin-left: 6px">调查类型</span>
          </template>
          <template slot-scope="scope">
            <el-form-item :prop="`userList.${scope.$index}.surveyType`" :rules="userListFormRules.surveyType">
              <el-select filterable clearable class="select-width" v-model="scope.row.surveyType" placeholder="请选择调查类型">
                <el-option v-for="item in bingliList" :key="item.label" :label="item.label"
                  :value="item.value" @click.native="handleBinli(item, scope.row)"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>



        <el-table-column prop="crowdTypeName" label="疑似人群分类" width="220">
          <template slot="header">
            <span style="margin-left: 6px">疑似人群分类</span>
          </template>
          <template slot-scope="scope">
            <el-form-item :prop="`userList.${scope.$index}.crowdTypeName`">
              <span v-if="scope.row.surveyStatus == 2">
                {{ scope.row.crowdTypeName }}
              </span>
              <el-select filterable clearable v-else class="select-width" v-model="scope.row.crowdTypeId" placeholder="选择人群分类">
                <el-option v-for="item in typepopSelect" :key="item.id" :label="item.ruleName"
                  :value="item.id" @click.native="handelCrowdType(item, scope.row)"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="userPlace" label="涉事场所" width="220">
          <template slot-scope="scope">
            <el-form-item :prop="`userList.${scope.$index}.placeList`">
              <span v-if="scope.row.surveyStatus == 2">
                {{ handelPlaceName(scope.row.placeList) }}
              </span>
              <el-select v-else class="select-width" v-model="scope.row.placeIds" placeholder="选择涉事场所" value-key="id"
                @change="handelPlaceChange($event, scope.row)" multiple>
                <el-option v-for="item in userPlaceSelect" :key="item.id" :label="item.placeName" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="surveyUserName" label="流调员" width="200">
          <template slot="header">
            <span style="color: #f56c6c">*</span>
            <span style="margin-left: 6px">流调员</span>
          </template>
          <template slot-scope="scope">
            <el-form-item :prop="`userList.${scope.$index}.surveyUserName`" :rules="userListFormRules.surveyUserName">
              <span v-if="scope.row.surveyStatus == 2">
                {{ scope.row.surveyUserName }}
              </span>
              <el-select v-else v-model="scope.row.surveyUserId" placeholder="请选择" class="select-width" filterable>
                <el-option-group v-for="group in surveyGroupUserListSelect" :key="group.id"
                  :label="group.groupName">
                  <el-option v-for="user in group.userList" :key="user.userId" :label="user.userName"
                    :value="user.userId" @click.native="handleSurveyUserName(scope.row, group, user)">
                    <span style="float: left">{{ user.userName }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">
                      {{ user.isLeader == 1 ? "组长" : "组员" }}
                    </span>
                    <span style="margin-left: 10px; font-size: 14px">{{
                        user.taskNum
                    }}</span>
                  </el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column prop="taskSource" label="状态" show-overflow-tooltip width="100">
          <template v-slot="scope">
            <el-form-item>
              <span>{{ scope.row.surveyStatus | surveyStatusFilter }}</span>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="surveyUserName" label="流调结果" show-overflow-tooltip width="100">
          <template v-slot="scope">
            <el-form-item>
              <div>{{ scope.row.surveyResult | surveyResultFilter }}</div>
            </el-form-item>
          </template>
        </el-table-column>

      </el-table>
    </el-form>
    <!-- 涉事场所 流调人员 -->
    <dialog-info :title="setDialogTitle" :dialogVisible="setDialogVisible" :isShowCancel="true"
      @cancelDialog="closeSetDialog" @closeDialog="closeSetDialog" @confirmDialog="confirmSetDialog">
      <!-- 设置涉事场所 -->
      <el-select v-show="setDialogType == 1" v-model="surveyUserName" placeholder="请选择" class="select-width" filterable>
        <el-option-group v-for="group in surveyGroupUserListSelect" :key="group.groupName" :label="group.groupName">
          <el-option v-for="user in group.userList" :key="user.userId" :label="user.userName" :value="user.userId"
            @click.native="setSurveyUserName(group, user)">
            <span style="float: left">{{ user.userName }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">
              {{ user.isLeader == 1 ? "组长" : "组员" }}
            </span>
            <span style="margin-left: 10px; font-size: 14px">{{
                user.taskNum
            }}</span>
          </el-option>
        </el-option-group>
      </el-select>
      <!-- 设置流调人员 -->
      <el-select v-show="setDialogType == 2" class="select-width" v-model="placeIds" placeholder="请选择" value-key="id"
        multiple @change="setUserPlace">
        <el-option v-for="item in userPlaceSelect" :key="item.id" :label="item.placeName" :value="item"></el-option>
      </el-select>
    </dialog-info>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  exportTaskTemplate,
} from "@/api/DiseaseControlManagement/DiseaseControlManagement";
import DialogInfo from "@/components/DialogInfo";
import { isIDCard, isPhone } from "@/utils/validate";
import { SURVEY_STATUS, SURVEY_RESULT } from "@/common/constants";
export default {
  name: "ResidentBasicInfo",
  props: {
    //新增或者修改类型
    type: {
      type: String,
      default: "",
    },
    //流调人员组
    surveyGroupUserListSelect: {
      type: Array,
      default: function () {
        return [];
      },
    },
    //涉事场所
    userPlaceSelect: {
      type: Array,
      default: function () {
        return [];
      },
    },
    //任务状态
    preTaskStatus: {
      type: String,
      default: "",
    },
    //上传文件请求头
    myHeaders: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  components: { DialogInfo },
  data() {
    const phoneCheck = (rule, value, callback) => {
      if (value && !isPhone(value)) {
        callback(new Error("请输入正确格式的手机号"));
      } else {
        callback();
      }
    };
    const cardNoCheck = (rule, value, callback) => {
      if (value && !isIDCard(value)) {
        callback(new Error("请输入正确格式的身份证号"));
      } else {
        callback();
      }
    };
    return {
      upload: "/ohealth/api/v1/epidemicControl/pretaskuser/uploadPreTaskExcel", //居民人员上传地址
      setDialogVisible: false, //设置对话框
      setDialogTitle: "", //设置对话框标题
      setDialogType: "", //设置流调人员为1，设置涉事场所为2
      selectResidents: [], //选择的居民信息
      surveyUserName: "", //设置的流调人员姓名
      surveyUserId: "", //设置的流调人员id
      surveyGroupId: "", //设置的流调组id
      placeList: [], //设置的涉事场所
      placeIds: [], //设置的涉事场所ids,用于显示
      userListForm: {
        userList: [], //居民信息
      },
      bingliList:[
        {
          value:'1',
          label:'密接其它人员调查'
        },
        {
          value:'2',
          label:'阳性病例调查'
        }
      ], //病例协查
      userListFormRules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        surveyType: [
          { required: true, message: "请选择调查类型", trigger: "blur" },
        ],
        cardNo: [{ validator: cardNoCheck, trigger: "blur" }],
        linkPhone: [{ validator: phoneCheck, trigger: "blur" }],
        surveyUserName: [
          { required: true, message: "请选择流调员", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      typepopSelect: (state) => state.user.typepopSelect, // 人群分类
    }),
  },
  filters: {
    surveyStatusFilter(val) {
      const surveyStatusValue = SURVEY_STATUS.find((item) => {
        return item.value == val;
      });
      return surveyStatusValue.label;
    },
    surveyResultFilter(val) {
      if (!val) return "-";
      const surveyResultValue = SURVEY_RESULT.find((item) => {
        return item.value == val;
      });
      return surveyResultValue.label;
    },
  },
  created(){
    console.log(this.surveyGroupUserListSelect,'打印this.surveyGroupUserListSelect')
    
  },
  methods: {
    //新增居民
    addResidentRow() {
      this.userListForm.userList.push({
        name: "",
        cardType: 1,
        cardNo: "",
        linkPhone: "",
        placeList: [],
        placeIds: [], //需要回显涉事场所
        crowdTypeId: "",
        crowdTypeCode: "",
        crowdTypeName: "",
        surveyGroupId: "",
        surveyUserId: "",
        surveyUserName: "",
        surveyStatus: 0, //待流调
        surveyType:'1'
      });
    },
    //删除居民
    deleteResidentRow(index) {
      this.userListForm.userList.splice(index, 1);
    },
    //导入居民文件处理数据
    handelSuccess(res) {
      if (res.code == "200") {
        const loadResidents = res.data;
        loadResidents.forEach((item) => {
          let crowdTypeId, crowdTypeCode, crowdTypeName;
          for (let i in this.typepopSelect) {
            if (item.crowdTypeName == this.typepopSelect[i].ruleName) {
              crowdTypeId = this.typepopSelect[i].id;
              crowdTypeCode = this.typepopSelect[i].code;
              crowdTypeName = this.typepopSelect[i].ruleName;
            }
          }
          let surveyType;
          for (let i in this.bingliList) {
            if (item.surveyTypeName == this.bingliList[i].label) {
              surveyType = this.bingliList[i].value;
            }
          }
          this.userListForm.userList.push({
            name: item.name,
            cardType: 1,
            cardNo: item.cardNo,
            linkPhone: item.linkPhone,
            surveyType: surveyType,
            placeList: [],
            placeIds: [], //需要回显涉事场所
            crowdTypeId: crowdTypeId,
            crowdTypeCode: crowdTypeCode,
            crowdTypeName: crowdTypeName,
            surveyGroupId: "",
            surveyUserId: "",
            surveyUserName: "",
            surveyStatus: 0, //待流调
          });
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    // 病例选择
    handleBinli(item,row){
      console.log(item,'打印item')
      row.surveyType = item.value;
    },
    //选择人群分类
    handelCrowdType(item, row) {
      row.crowdTypeId = item.id;
      row.crowdTypeCode = item.code;
      row.crowdTypeName = item.ruleName;
    },
    //选择涉事场所
    handelPlaceChange(value, row) {
      row.placeList = value.map((item) => {
        return {
          placeInvolvedId: item.id,
          placeName: item.placeName,
        };
      });
    },
    //选择调查人员
    handleSurveyUserName(row, group, user) {
      // console.log("group", group, user);
      row.surveyGroupId = group.id;
      row.surveyUserId = user.userId;
      row.surveyUserName = user.userName;
    },

    //设置涉事场所、流调员
    setResidentInfo(type) {
      this.setDialogType = type;
      if (this.selectResidents.length == 0) {
        this.$message({
          message: "请选择居民",
          type: "warning",
        });
        return;
      }
      this.setDialogVisible = true;
      if (this.setDialogType == "1") {
        this.setDialogTitle = "流调员设置";
      } else if (this.setDialogType == "2") {
        this.setDialogTitle = "涉事场所设置";
      }
    },
    //关闭设置对话框
    closeSetDialog() {
      this.setDialogVisible = false;
    },
    //确定
    confirmSetDialog() {
      if (this.setDialogType == "1") {
        this.selectResidents.forEach((resident) => {
          this.userListForm.userList = this.userListForm.userList.map(
            (user) => {
              if (JSON.stringify(resident) == JSON.stringify(user)) {
                user.surveyGroupId = this.surveyGroupId;
                user.surveyUserId = this.surveyUserId;
                user.surveyUserName = this.surveyUserName;
              }
              return user;
            }
          );
        });
      } else if (this.setDialogType == "2") {
        this.selectResidents.forEach((resident) => {
          this.userListForm.userList = this.userListForm.userList.map(
            (user) => {
              if (JSON.stringify(resident) == JSON.stringify(user)) {
                user.placeList = this.placeList;
                user.placeIds = this.placeIds;
              }
              return user;
            }
          );
        });
      } else {
        //无处理
      }
      this.setDialogVisible = false;
      this.surveyGroupId = "";
      this.surveyUserId = "";
      this.surveyUserName = "";
      this.placeList = [];
      this.placeIds = [];
    },
    //设置调查人员
    setSurveyUserName(group, user) {
      this.surveyGroupId = group.id;
      this.surveyUserId = user.userId;
      this.surveyUserName = user.userName;
    },
    //设置涉事场所
    setUserPlace(val) {
      this.placeList = val.map((item) => {
        return {
          placeInvolvedId: item.id,
          placeName: item.placeName,
        };
      });
      this.palceIds = val.map((item) => {
        return {
          id: item.id,
        };
      });
    },
    //选择居民
    handleSelectionChange(val) {
      this.selectResidents = val;
    },
    //居民复选框是否可点
    selectable(row, index) {
      if (row.surveyStatus == 2) return false;
      return true;
    },
    //提交
    comm() {
      return this.$refs.userListFormRef.validate();
    },
    //详情
    residentDetail(row) {
      if (!row.epidemiologicalSurveyId) return;
      //处理数据
      row = { ...row, id: row.epidemiologicalSurveyId };
      this.$emit("showDetail", row);
    },
    //涉事场所数据处理
    handelPlaceName(placeList) {
      return placeList
        .map((item) => {
          return item.placeName;
        })
        .join(",");
    },
    // 导入下载模版
    async downloadTemplate() {
      try {
        let { data } = await exportTaskTemplate();
        // console.log("downloadTemplate -> data", data);
        // const url = window.URL.createObjectURL(
        //   new Blob([data], { type: "application/x-download" })
        // );
        // const link = document.createElement("a");
        // link.style.display = "none";
        // link.href = url;
        // link.setAttribute("download", `疾控任务管理导入模板.xlsx`);
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);

        const blob = new Blob([data]);
        let url = window.URL.createObjectURL(
          new Blob([data], { type: "application/x-download" })
        );
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", `疾控任务管理导入模板.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (error) { }
    },
  },
};
</script>

<style lang="scss" scoped>
.select-width {
  width: 90%;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.blue {
  width: 4px;
  height: 20px;
  background-color: #3476f5;
  margin-right: 6px;
}

.resident-btns {
  display: flex;
  margin-bottom: 20px;
}

.btns {
  display: flex;
  margin-left: 30px;
}

.btns-item {
  margin-right: 10px;
  width: 120px;
}

.upload-item {
  margin-left: 10px;
  margin-right: 20px;
  background-color: #409eff;
  border-radius: 4px;
  text-align: center;
}

.el-form-item {
  margin-bottom: 0;
}

::v-deep .el-table__body {
  padding-bottom: 30px;
}
</style>