<template>
  <div>
    <div v-if="!isShowDetail">
      <div class="top_title">
        <i class="el-icon-back" style="font-size: 20px" @click="goBack"></i>
        <span style="margin-left: 20px">居民信息</span>
      </div>
      <el-divider></el-divider>
      <div class="content-title">任务基本信息</div>
      <el-row>
        <DetailItem
          left-label="任务登记员"
          :right-value="rowData.crtUserName || '-'"
        />
        <DetailItem
          left-label="任务登记时间"
          :right-value="rowData.crtTime || '-'"
        />
        <DetailItem
          left-label="任务编号"
          :right-value="rowData.preTaskNo || '-'"
        />
      </el-row>
      <el-row>
        <DetailItem
          left-label="信息来源"
          :right-value="taskInfo.infoSource || '-'"
        />
        <DetailItem
          left-label="信息类别"
          :right-value="taskInfo.infoType || '-'"
        />
        <DetailItem
          left-label="事件名称"
          :right-value="taskInfo.eventName || '-'"
        />
      </el-row>
      <el-row>
        <DetailItem
          left-label="任务描述"
          :right-value="taskInfo.taskDesc || '-'"
        />
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="detail-content">
            <span class="left-label" style="width: 130px">附件:</span>
            <div style="display: flex; flex-direction: column">
              <span v-if="fileList.length == 0">-</span>
              <template v-else>
                <div
                  class="file"
                  v-for="file in fileList"
                  :key="file.fileUrl"
                  @click="handlePreview(file)"
                >
                  <i class="el-icon-document"></i>
                  <div style="margin-left: 10px">{{ file.fileName }}</div>
                </div>
              </template>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="content-title">调查居民信息</div>
      <el-table
        :is-show-select="true"
        :data="userList"
        table-size="medium"
        border
      >
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="residentDetail(scope.row)"
              >{{
                scope.row.epidemiologicalSurveyId ? "查看详情" : "-"
              }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="name" label="姓名" width="100" />
        <el-table-column prop="cardNo" label="身份证号码" width="200" />
        <el-table-column prop="linkPhone" label="联系电话" width="200" />
        <el-table-column
          prop="crowdTypeName"
          label="疑似人群分类"
          width="200"
        />
        <el-table-column prop="crowdTypeName" label="涉事场所" width="200" />
        <el-table-column prop="surveyUserName" label="流调员" width="120" />
        <el-table-column
          prop="surveyStatus"
          label="状态"
          show-overflow-tooltip
          width="120"
        >
          <template v-slot="scope">{{
            scope.row.surveyStatus | surveyStatusFilter
          }}</template>
        </el-table-column>
        <el-table-column
          prop="surveyResult"
          label="流调结果"
          show-overflow-tooltip
          width="120"
        >
          <template v-slot="scope">{{
            scope.row.surveyResult | surveyResultFilter
          }}</template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 居民详情 -->
    <div v-if="isShowDetail">
      <residentInformation ref="residentInformationRef" />
    </div>
  </div>
</template>
<script>
import TableModel from "@/components/TableModel";
import DetailItem from "@/views/QuarantineRegistration/modules/DetailItem";
import { getPreTaskInfoByIdApi } from "@/api/DiseaseControlManagement/DiseaseControlManagement";
import { INFO_TYPE, SURVEY_RESULT, SURVEY_STATUS } from "@/common/constants";
import { downloadExportFile } from "@/utils/utils";
import residentInformation from "@/views/QuarantineRegistration/residentInformation";
import { Loading } from "element-ui";
export default {
  name: "SurveyTaskDetail",
  components: { TableModel, DetailItem, residentInformation },
  data() {
    return {
      rowData: {}, //列表行数据
      taskInfo: {}, //基本信息
      userList: [], //居民信息
      fileList: [], //文件列表
      isShowDetail: false, //居民详情
    };
  },
  filters: {
    surveyResultFilter(val) {
      if (!val) return "-";
      const surveyResultValue = SURVEY_RESULT.find((item) => {
        return item.value == val;
      });
      return surveyResultValue.label;
    },
    surveyStatusFilter(val) {
      const surveyStatusValue = SURVEY_STATUS.find((item) => {
        return item.value == val;
      });
      return surveyStatusValue.label;
    },
  },

  methods: {
    // 返回上一页面
    goBack() {
      this.$parent.isTaskDetail = false;
    },
    //获取详情
    async getPreTaskInfoById(row) {
      this.rowData = row;
      const params = { id: row.id };
      const { data } = await getPreTaskInfoByIdApi({ params });
      if (data.code == "200") {
        this.taskInfo = data.data.info;
        this.userList = data.data.userList;
        this.fileList = data.data.fileList;
        //信息类别处理
        const { infoType } = this.taskInfo;
        const infoTypeValue = INFO_TYPE.find((item) => {
          return item.value == infoType;
        });
        this.taskInfo.infoType = infoTypeValue.label;
        //文件列表处理
      }
    },
    //下载文件
    handlePreview(file) {
      let loadingInstance = Loading.service();
      Loading.service();

      //获取文件后缀
      const suffix = file.fileName.substring(
        file.fileName.lastIndexOf(".") + 1
      );
      if (["png", "jpg"].includes(suffix)) {
        let alink = document.createElement("a");
        alink.href = file.fileUrl;
        alink.target = "_blank";
        alink.download = file.fileName; //fileName保存提示中用作预先填写的文件名
        alink.click();
      } else if (["docx", "doc", "pdf", "xls", "xlsx"].includes(suffix)) {
        downloadExportFile(file.fileUrl, file.fileName, suffix);
      } else {
        return;
      }

      setTimeout(() => {
        loadingInstance.close();
      }, 1000);
    },
    //详情
    residentDetail(row) {
      console.log(row,'7777777777');
      if(row.surveyType == 2) return
      if (!row.epidemiologicalSurveyId) return;
      //处理数据
      row = { ...row, id: row.epidemiologicalSurveyId };
      this.isShowDetail = true;
      this.$nextTick(() => {
        this.$refs.residentInformationRef.getSurveyPersonInfo(row);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-title {
  padding: 20px 0;
  color: #333;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-left: 14px;

  &:after {
    content: "";
    width: 5px;
    height: 23px;
    background: #409eff;
    position: absolute;
    top: 22px;
    left: -12px;
  }
}

.top_title {
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  color: #666666;
}

.detail-content {
  font-size: 15px;
  color: #666;
  padding: 10px 0;
  margin-left: 10px;
  display: flex;

  .left-label {
    display: inline-block;
    text-align: right;
    margin-right: 16px;
  }
}

.file {
  display: flex;
  padding: 10px;
  position: relative;
  cursor: pointer;
}

.file::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #666666;
  transform: scaleY(0.5);
}
</style>