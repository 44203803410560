<template>
  <div class="pagination" :style="paginationStyle">
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
      :page-sizes="pageSizes" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
    </el-pagination>
  </div>
</template>

<script>
  export default {
    name: 'PaginationInfo',
    props: {
      //总条数
      total: {
        type: [Number, String],
        default: 0
      },
      //每页条数
      pageSize: {
        type: Number,
        default: 10
      },
      //每页条数选项
      pageSizes: {
        type: Array,
        default: function () {
          return []
        }
      },
      //当前页
      currentPage: {
        type: Number,
        default: 1
      },
      //分页样式
      paginationStyle: {
        type: Object,
        default: function () {
          return {}
        }
      }
    },
    methods: {
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.$emit('sizeChange',val)
      },
      handleCurrentChange(val) {
        this.$emit('currentChange',val)
        console.log(`当前页: ${val}`);
      }
    },
  };
</script>

<style lang="scss" scoped>
  .pagination {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    height: 40px;
  }
</style>