<template>
  <div>
    <div v-show="!isTaskDetail & !isAddTask">
      <el-row type="flex" justify="space-between" align="middle">
        <div class="left-item">
          <el-date-picker class="time-width" v-model="searchForm.taskTime" type="daterange" range-separator="至"
            start-placeholder="任务下达开始时间" end-placeholder="任务下达结束时间" :default-time="['00:00:00', '23:59:59']" clearable>
          </el-date-picker>
          <el-cascader class="query-width" ref="infoSourceRef" placeholder="信息来源" v-model="searchForm.infoSourceCode"
            :props="{ ...props, checkStrictly: true }" @change="handleInfoSource" clearable></el-cascader>
          <el-select v-model="searchForm.preTaskStatus" placeholder="任务状态" class="query-width" clearable>
            <el-option v-for="item in pretaskStatusSelect" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="searchForm.eventId" placeholder="请选择事件" class="query-width" clearable>
            <el-option v-for="item in eventNameSelect" :key="item.id" :label="item.eventName" :value="item.id">
            </el-option>
          </el-select>
          <el-input v-model="searchForm.searchValue" placeholder="居民姓名/证件号" class="query-width" clearable></el-input>
        </div>
        <div class="right-item">
          <el-button type="primary" class="btn-width" @click="getPreTaskPage('btn')">查询</el-button>
          <el-button type="primary" class="btn-width" @click="addTask('add')">新增任务</el-button>
        </div>
      </el-row>
      <div style="margin-top:20px">
        <TableModel :is-show-select="true" :loading="loading" :table-data="tableList" :goBeyondHeight="40"
          table-size="medium">
          <el-table-column fixed="left" label="操作" width="100">
            <template slot-scope="scope">
              <el-button v-if="['2', '3'].includes(scope.row.preTaskStatus)" type="text" size="small"
                @click="taskDetail(scope.row)">查看详情</el-button>
              <el-button v-else="['0','1'].includes(scope.row.preTaskStatus)" type="text" size="small"
                @click="addTask('edit', scope.row.id)">编辑</el-button>
              <el-button v-if="scope.row.preTaskStatus == 0" type="text" size="small" style="color:gray"
                @click="cancel(scope.row.id)">取消</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="crtTime" label="任务下达时间" width="180">
            <template slot-scope="scope">{{ format(scope.row.crtTime) }}</template>
          </el-table-column>
          <el-table-column prop="infoSource" label="信息来源" width="200"></el-table-column>
          <el-table-column prop="infoType" label="信息类别" width="150">
            <template v-slot="scope">{{ scope.row.infoType | infoTypeFilter }}</template>
          </el-table-column>
          <el-table-column prop="eventName" label="事件名称" width="150" />
          <el-table-column prop="crtUserName" label="信息登记员" width="200" />
          <el-table-column prop="surveyUsers" label="流调人员" width="200" />
          <el-table-column prop="preTaskNo" label="任务编号" show-overflow-tooltip width="200" />
          <el-table-column prop="surveyUsersNum" label="调查居民数量" show-overflow-tooltip width="120" />
          <el-table-column prop="preTaskStatus" label="状态" show-overflow-tooltip width="200">
            <template v-slot="scope">{{ scope.row.preTaskStatus | preTaskStatusFilter }}</template>
          </el-table-column>
          <el-table-column prop="processingNum" label="进度" show-overflow-tooltip width="200">
            <template v-slot="scope">
              <div class="process">
                <div class="process-num blue" v-if="scope.row.processingNum != 0">{{ scope.row.processingNum }}</div>
                <div class="process-num green" v-if="scope.row.finishNum != 0">{{ scope.row.finishNum }}</div>
                <div class="process-num gray" v-if="scope.row.unProcessingNum != 0">{{ scope.row.unProcessingNum }}</div>
              </div>
            </template>
          </el-table-column>
        </TableModel>
      </div>
      <pagination-info :total="total" :pageSize="10" :pageSizes="[10, 20, 30, 50, 100]" :currentPage="pageNumber"
        @sizeChange="sizeChange" @currentChange="currentChange"></pagination-info>
    </div>
    <!-- 任务详情 -->
    <div v-show="isTaskDetail">
      <SurveyTaskDetail ref="surveyTaskDetailRef"></SurveyTaskDetail>
    </div>
    <!-- 新增或修改任务 -->
    <div v-if="isAddTask">
      <AddSurveyTask :props="props" :eventNameSelect="eventNameSelect" :type="type" :id="id"></AddSurveyTask>
    </div>
  </div>
</template>

<script>
import { http } from "@/api/index";
import PaginationInfo from "@/components/PaginationInfo";
import TableModel from "@/components/TableModel";
import AddSurveyTask from "./AddSurveyTask";
import SurveyTaskDetail from "./components/SurveyTaskDetail";
import {
  getPreTaskPageApi,
  getEventListApi,
  cancelPreTaskApi
} from "@/api/DiseaseControlManagement/DiseaseControlManagement";
import { PRETASK_STATUS, INFO_TYPE } from "@/common/constants";
export default {
  name: "SurveyTaskList",
  components: {
    PaginationInfo,
    TableModel,
    AddSurveyTask,
    SurveyTaskDetail
  },
  data() {
    return {
      total: 0,
      pageSize: 10, // 每页条数
      pageNumber: 1, //页码
      loading: false,
      tableList: [],
      eventNameSelect: [], //事件名称列表
      isTaskDetail: false, //任务详情页面
      isAddTask: false, //新增或修改任务页面
      dialogVisible: false, //取消任务对话框
      pretaskStatusSelect: PRETASK_STATUS,
      searchForm: {
        taskTime: [], //任务下达时间
        searchValue: "",
        infoSourceCode: [], //信息来源
        infoSource: "", //信息来源
        preTaskStatus: "0", //任务状态
        eventId: "" //事件id
      },

      type: "", //新增或者修改
      //3级
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          if (node.level == 0) {
            let cities = JSON.parse(localStorage.getItem("dqlist"));
            setTimeout(() => {
              resolve(cities);
            }, 0);
          } else {
            let formm = { label: node.data.value };
            http({
              method: "post",
              url: `/ohealth/api/v1/system/common/subList`,
              data: formm
            }).then(function (response) {
              let list = response.data.data;
              const nodes = list.map(item => ({
                value: item.value,
                label: item.label,
                leaf: item.leaf == 3
              }));
              resolve(nodes);
            });
          }
        }
      }
    };
  },
  created() {
    this.getPreTaskPage();
    this.getEventList();
  },
  mounted() { },
  filters: {
    //信息类别
    infoTypeFilter(value) {
      const valueName = INFO_TYPE.find(item => {
        return item.value == value;
      });
      return valueName ? valueName.label : "";
    },
    //任务状态
    preTaskStatusFilter(value) {
      const valueName = PRETASK_STATUS.find(item => {
        return item.value == value;
      });
      return valueName ? valueName.label : "";
    }
  },
  methods: {
    //获取事件列表
    async getEventList() {
      const params = {};
      const { data } = await getEventListApi({ params });
      if (data.code == "200") {
        this.eventNameSelect = data.data;
      }
    },
    //选择信息来源
    handleInfoSource() {
      let node = this.$refs.infoSourceRef.getCheckedNodes()[0];
      if (node) {
        let infoSource = node.pathLabels.toString();
        this.searchForm.infoSource = infoSource;
      }
    },
    //查询任务列表
    async getPreTaskPage(val) {
      this.loading = true;
      if (val == "btn") {
        this.pageNumber = 1;
      }
      const {
        eventId,
        infoSourceCode,
        preTaskStatus,
        searchValue,
        taskTime
      } = this.searchForm;
      const startTime =
        taskTime && taskTime.length > 0
          ? this.$moment(taskTime[0]).format("YYYY-MM-DD HH:mm:ss")
          : "";
      const endTime =
        taskTime && taskTime.length > 0
          ? this.$moment(taskTime[1]).format("YYYY-MM-DD HH:mm:ss")
          : "";
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        infoSourceCode: infoSourceCode.toString(),
        startTime,
        endTime,
        eventId,
        preTaskStatus,
        searchValue
      };
      const { data } = await getPreTaskPageApi({ params });
      if (data.code == "200") {
        this.loading = false;
        this.tableList = data.data.list;
        this.total = data.data.totalRow;
      }
    },
    //处理时间
    format(val) {
      return this.$moment(val).format("YYYY-MM-DD HH:mm");
    },
    //新增任务
    addTask(type, id) {
      this.isAddTask = true;
      this.type = type;
      this.id = id ? id : "";
    },
    sizeChange(val) {
      this.pageSize = val;
      this.getPreTaskPage();
    },
    currentChange(val) {
      this.pageNumber = val;
      this.getPreTaskPage();
    },
    //查看详情
    taskDetail(row) {
      this.isTaskDetail = true;
      this.$refs.surveyTaskDetailRef.getPreTaskInfoById(row);
    },
    // 编辑
    edit(type, id) {
      this.isAddTask = true;
      this.type = type;
    },
    //取消对话框弹出
    cancel(id) {
      this.$confirm("此操作将取消该任务, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        const params = { id };
        const { data } = await cancelPreTaskApi(params);
        if (data.code == "200") {
          this.$message({
            type: "success",
            message: "取消成功!"
          });
          this.getPreTaskPage();
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.time-width {
  width: 360px;
  margin-right: 20px;
}

.query-width {
  margin-top: 20px;
  margin-right: 20px;
  width: 220px;
}

.right-item {
  display: flex;
}

.btn-width {
  margin-top: 20px;
  width: 100px;
}

.process {
  display: flex;
}

.process-num {
  height: 22px;
  width: 40px;
  text-align: center;
  color: #fff;
  flex: 1;
}

.green {
  background-color: #19be6b;
}

.blue {
  background-color: #409eff;
}

.gray {
  background-color: #cccccc;
}
</style>